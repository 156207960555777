var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vfc-day vfc-week-number",
      style: { borderRightColor: _vm.borderColor },
    },
    [_c("span", { staticClass: "vfc-span-day" }, [_vm._v(_vm._s(_vm.number))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }