var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.fConfigs.isModal && _vm.fConfigs.isDateRange
      ? _c(
          "div",
          {
            staticClass: "vfc-multiple-input",
            class: { "vfc-dark": _vm.fConfigs.isDark },
          },
          [
            _vm._t(
              "dateRangeInputs",
              function () {
                return [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.dateRangeSelectedStartDate,
                        expression: "dateRangeSelectedStartDate",
                      },
                    ],
                    attrs: {
                      type: "text",
                      title: "Start Date",
                      placeholder: _vm.fConfigs.placeholder.split(" ")[0],
                      readonly: !_vm.fConfigs.isTypeable,
                      maxlength: _vm.fConfigs.dateFormat.length,
                    },
                    domProps: { value: _vm.dateRangeSelectedStartDate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.dateRangeSelectedStartDate = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.dateRangeSelectedEndDate,
                        expression: "dateRangeSelectedEndDate",
                      },
                    ],
                    attrs: {
                      type: "text",
                      title: "End Date",
                      placeholder: _vm.fConfigs.placeholder.split(" ")[0],
                      readonly: !_vm.fConfigs.isTypeable,
                      maxlength: _vm.fConfigs.dateFormat.length,
                    },
                    domProps: { value: _vm.dateRangeSelectedEndDate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.dateRangeSelectedEndDate = $event.target.value
                      },
                    },
                  }),
                ]
              },
              {
                startDate: _vm.dateRangeSelectedStartDate,
                endDate: _vm.dateRangeSelectedEndDate,
                isTypeable: _vm.fConfigs.isTypeable,
              }
            ),
          ],
          2
        )
      : _vm.fConfigs.isModal && _vm.fConfigs.isDatePicker
      ? _c(
          "div",
          { class: { "vfc-dark": _vm.fConfigs.isDark } },
          [
            _vm._t(
              "datePickerInput",
              function () {
                return [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.singleSelectedDate,
                        expression: "singleSelectedDate",
                      },
                    ],
                    staticClass: "vfc-single-input",
                    attrs: {
                      type: "text",
                      title: "Date",
                      placeholder: _vm.fConfigs.placeholder,
                      readonly: !_vm.fConfigs.isTypeable,
                      maxlength: _vm.fConfigs.dateFormat.length,
                    },
                    domProps: { value: _vm.singleSelectedDate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.singleSelectedDate = $event.target.value
                      },
                    },
                  }),
                ]
              },
              {
                selectedDate: _vm.singleSelectedDate,
                isTypeable: _vm.fConfigs.isTypeable,
              }
            ),
          ],
          2
        )
      : _vm.fConfigs.isModal && _vm.fConfigs.isMultipleDatePicker
      ? _c(
          "div",
          {
            staticClass: "vfc-tags-input-root",
            class: { "vfc-dark": _vm.fConfigs.isDark },
          },
          [
            _c(
              "div",
              { staticClass: "vfc-tags-input-wrapper-default vfc-tags-input" },
              [
                _vm._l(_vm.calendar.selectedDates, function (date, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      staticClass:
                        "vfc-tags-input-badge vfc-tags-input-badge-pill vfc-tags-input-badge-selected-default",
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(date.date) },
                      }),
                      _vm._v(" "),
                      _c("a", {
                        staticClass: "vfc-tags-input-remove",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.removeFromSelectedDates(index)
                          },
                        },
                      }),
                    ]
                  )
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.calendar.selectedDatesItem,
                      expression: "calendar.selectedDatesItem",
                    },
                  ],
                  attrs: { type: "text", placeholder: "Add a date" },
                  domProps: { value: _vm.calendar.selectedDatesItem },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.$parent.addToSelectedDates.apply(
                        null,
                        arguments
                      )
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.calendar,
                        "selectedDatesItem",
                        $event.target.value
                      )
                    },
                  },
                }),
              ],
              2
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }