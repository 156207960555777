import { render, staticRenderFns } from "./WeekNumbers.vue?vue&type=template&id=6dd0cf97&scoped=true&"
import script from "./WeekNumbers.vue?vue&type=script&lang=js&"
export * from "./WeekNumbers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dd0cf97",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/symphony/webapps/frontend/includes/vuejs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6dd0cf97')) {
      api.createRecord('6dd0cf97', component.options)
    } else {
      api.reload('6dd0cf97', component.options)
    }
    module.hot.accept("./WeekNumbers.vue?vue&type=template&id=6dd0cf97&scoped=true&", function () {
      api.rerender('6dd0cf97', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/vue-functional-calendar/src/components/WeekNumbers.vue"
export default component.exports