var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "popoverElement",
      staticClass: "vfc-popover-container",
      attrs: { tabindex: "0" },
    },
    [
      _c("PickerInputs", {
        attrs: {
          fConfigs: _vm.fConfigs,
          singleSelectedDate: _vm.singleSelectedDate,
          calendar: _vm.calendar,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "dateRangeInputs",
              fn: function (props) {
                return [
                  _vm._t("dateRangeInputs", null, {
                    startDate: props.startDate,
                    endDate: props.endDate,
                    isTypeable: _vm.fConfigs.isTypeable,
                  }),
                ]
              },
            },
            {
              key: "datePickerInput",
              fn: function (props) {
                return [
                  _vm._t("datePickerInput", null, {
                    selectedDate: props.selectedDate,
                    isTypeable: _vm.fConfigs.isTypeable,
                  }),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showCalendar,
              expression: "showCalendar",
            },
          ],
          ref: "mainContainer",
          staticClass: "vfc-main-container",
          class: {
            "vfc-modal":
              _vm.fConfigs.isModal &&
              (_vm.fConfigs.isDatePicker ||
                _vm.fConfigs.isDateRange ||
                _vm.fConfigs.isMultipleDatePicker),
            "vfc-dark": _vm.fConfigs.isDark,
          },
        },
        [
          _vm.showTimePicker
            ? _c("time-picker", {
                ref: "timePicker",
                attrs: { height: _vm.$refs.popoverElement.clientHeight },
              })
            : [
                _c(
                  "div",
                  { staticClass: "vfc-calendars-container" },
                  [
                    _c("Arrows", {
                      attrs: {
                        isMultiple: false,
                        fConfigs: _vm.fConfigs,
                        allowPreDate: _vm.allowPreDate,
                        allowNextDate: _vm.allowNextDate,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "navigationArrowLeft",
                            fn: function () {
                              return [_vm._t("navigationArrowLeft")]
                            },
                            proxy: true,
                          },
                          {
                            key: "navigationArrowRight",
                            fn: function () {
                              return [_vm._t("navigationArrowRight")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { ref: "calendars", staticClass: "vfc-calendars" },
                      _vm._l(_vm.listCalendars, function (calendarItem, key) {
                        return _c(
                          "div",
                          {
                            key: calendarItem.key,
                            staticClass: "vfc-calendar",
                          },
                          [
                            _c("month-year-picker", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showMonthPicker === key + 1 ||
                                    _vm.showYearPicker === key + 1,
                                  expression:
                                    "\n                showMonthPicker === key + 1 || showYearPicker === key + 1\n              ",
                                },
                              ],
                              ref: "monthContainer",
                              refInFor: true,
                              class: "vfc-" + _vm.fConfigs.titlePosition,
                              attrs: {
                                changeYearStep: _vm.changeYearStep,
                                "calendar-key": key,
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vfc-content" },
                              [
                                _c("Arrows", {
                                  attrs: {
                                    isMultiple: true,
                                    fConfigs: _vm.fConfigs,
                                    allowPreDate: _vm.allowPreDate,
                                    allowNextDate: _vm.allowNextDate,
                                    "calendar-key": key,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "navigationArrowLeft",
                                        fn: function () {
                                          return [_vm._t("navigationArrowLeft")]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "navigationArrowRight",
                                        fn: function () {
                                          return [
                                            _vm._t("navigationArrowRight"),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      tag: "div",
                                      name: _vm.getTransition_(),
                                      appear: "",
                                    },
                                  },
                                  [
                                    _vm.checkHiddenElement("month")
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "vfc-top-date",
                                            class:
                                              "vfc-" +
                                              _vm.fConfigs.titlePosition,
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "vfc-cursor-pointer vfc-underline":
                                                    _vm.fConfigs
                                                      .changeMonthFunction &&
                                                    _vm.isNotSeparatelyAndFirst(
                                                      key
                                                    ),
                                                  "vfc-underline-active":
                                                    _vm.showMonthPicker ===
                                                    key + 1,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    _vm.isNotSeparatelyAndFirst(
                                                      key
                                                    ) &&
                                                      _vm.openMonthPicker(
                                                        key + 1
                                                      )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(calendarItem.month)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "vfc-cursor-pointer vfc-underline":
                                                    _vm.fConfigs
                                                      .changeYearFunction &&
                                                    _vm.isNotSeparatelyAndFirst(
                                                      key
                                                    ),
                                                  "vfc-underline-active":
                                                    _vm.showYearPicker ===
                                                    key + 1,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    _vm.isNotSeparatelyAndFirst(
                                                      key
                                                    ) &&
                                                      _vm.openYearPicker(
                                                        key + 1
                                                      )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(calendarItem.year) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      tag: "div",
                                      name: _vm.getTransition_(),
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vfc-dayNames" },
                                      [
                                        _vm.fConfigs.showWeekNumbers
                                          ? _c("span")
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.fConfigs.dayNames,
                                          function (dayName, dayKey) {
                                            return _c(
                                              "span",
                                              {
                                                key: key + dayKey + 1,
                                                staticClass: "vfc-day",
                                              },
                                              [
                                                _vm.checkHiddenElement(
                                                  "dayNames"
                                                )
                                                  ? [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(dayName) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "transition-group",
                                  {
                                    attrs: {
                                      tag: "div",
                                      name: _vm.getTransition_(),
                                      appear: "",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      calendarItem.weeks,
                                      function (week, week_key) {
                                        return _c(
                                          "div",
                                          {
                                            key: key + week_key + 1,
                                            staticClass: "vfc-week",
                                          },
                                          [
                                            _vm.showWeekNumbers
                                              ? _c("WeekNumbers", {
                                                  attrs: {
                                                    number: week.number,
                                                    borderColor:
                                                      _vm.borderColor,
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm._l(
                                              week.days,
                                              function (day, day_key) {
                                                return _c("Day", {
                                                  key:
                                                    key +
                                                    week_key +
                                                    day_key +
                                                    1,
                                                  ref: "day",
                                                  refInFor: true,
                                                  attrs: {
                                                    isMultipleDateRange:
                                                      _vm.isMultipleDateRange,
                                                    day: day,
                                                    fConfigs: _vm.fConfigs,
                                                    calendar: _vm.calendar,
                                                    calendar_rates:
                                                      _vm.calendarRates,
                                                    helpCalendar:
                                                      _vm.helpCalendar,
                                                    week: week,
                                                    day_key: day_key,
                                                  },
                                                  on: {
                                                    dayMouseOver:
                                                      _vm.dayMouseOver,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (props) {
                                                          return [
                                                            _vm._t(
                                                              "default",
                                                              null,
                                                              {
                                                                week: props.week,
                                                                day: props.day,
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    calendarItem.weeks.length < 6 &&
                                    !_vm.fConfigs.isLayoutExpandable
                                      ? _vm._l(
                                          6 - calendarItem.weeks.length,
                                          function (moreWeekKey) {
                                            return _c(
                                              "div",
                                              {
                                                key:
                                                  key +
                                                  moreWeekKey +
                                                  "moreWeek",
                                                staticStyle: {
                                                  height: "32.6px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                     \n                  "
                                                ),
                                              ]
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.canClearRange || _vm.$slots["footer"]
                      ? _c("Footer", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "footer",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { on: { click: _vm.cleanRange } },
                                      [
                                        _vm._t("cleaner", function () {
                                          return [
                                            _vm.canClearRange &&
                                            _vm.fConfigs.isDateRange
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "rangeCleaner",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          _vm.rangeIsSelected
                                                            ? "active"
                                                            : "disabled",
                                                        ],
                                                        on: {
                                                          click: _vm.cleanRange,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Clear Range" +
                                                            _vm._s(
                                                              _vm.isMultipleDateRange &&
                                                                "s"
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm._t("footer"),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }