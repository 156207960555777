var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.oneArrows || _vm.manyArrows
      ? _c(
          "div",
          {
            staticClass: "vfc-separately-navigation-buttons",
            class: "vfc-" + _vm.fConfigs.arrowsPosition,
          },
          [
            _c(
              "div",
              {
                class: { "vfc-cursor-pointer": _vm.allowPreDate },
                on: {
                  click: function ($event) {
                    return _vm.$parent.PreMonth(
                      _vm.oneArrows ? 0 : _vm.calendarKey
                    )
                  },
                },
              },
              [
                _vm._t("navigationArrowLeft", function () {
                  return [
                    _c("div", {
                      staticClass: "vfc-arrow-left",
                      class: { "vfc-disabled": !_vm.allowPreDate },
                    }),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: { "vfc-cursor-pointer": _vm.allowNextDate },
                on: {
                  click: function ($event) {
                    return _vm.$parent.NextMonth(
                      _vm.oneArrows ? 0 : _vm.calendarKey
                    )
                  },
                },
              },
              [
                _vm._t("navigationArrowRight", function () {
                  return [
                    _c("div", {
                      staticClass: "vfc-arrow-right",
                      class: { "vfc-disabled": !_vm.allowNextDate },
                    }),
                  ]
                }),
              ],
              2
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }