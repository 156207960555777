var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vfc-time-picker-container" }, [
    _c("div", {
      staticClass: "vfc-close",
      on: {
        click: function ($event) {
          return _vm.close()
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "vfc-modal-time-mechanic" }, [
      _c(
        "div",
        { staticClass: "vfc-modal-time-line", attrs: { id: "time-line" } },
        [
          _c(
            "span",
            [
              _vm.$parent.fConfigs.isDateRange
                ? [
                    _c(
                      "span",
                      {
                        class: { "vfc-active": _vm.startDateActive },
                        on: {
                          click: function ($event) {
                            _vm.startDateActive = true
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$parent.calendar.dateRange.start))]
                    ),
                    _vm._v(" "),
                    _vm.$parent.calendar.dateRange.end
                      ? [
                          _c("span", [_vm._v("-")]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              class: { "vfc-active": !_vm.startDateActive },
                              on: {
                                click: function ($event) {
                                  _vm.startDateActive = false
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$parent.calendar.dateRange.end))]
                          ),
                        ]
                      : _vm._e(),
                  ]
                : _vm.$parent.fConfigs.isMultipleDatePicker
                ? [_vm._v(_vm._s(_vm.getCurrentDateTime))]
                : [_vm._v(_vm._s(_vm.$parent.calendar.selectedDateTime))],
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "vfc-time-picker" }, [
        _c(
          "div",
          {
            ref: "hourList",
            staticClass: "vfc-time-picker__list vfc-time-picker__list--hours",
          },
          _vm._l(24, function (i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "vfc-time-picker__item",
                class: {
                  "vfc-time-picker__item--selected":
                    _vm.checkHourActiveClass(i),
                },
                on: {
                  click: function ($event) {
                    _vm.changeHour(_vm.formatTime(i))
                  },
                },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.formatTime(i)) + "\n        "
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "minuteList",
            staticClass: "vfc-time-picker__list vfc-time-picker__list--minutes",
          },
          _vm._l(60, function (i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "vfc-time-picker__item",
                class: {
                  "vfc-time-picker__item--selected":
                    _vm.checkMinuteActiveClass(i),
                },
                on: {
                  click: function ($event) {
                    _vm.changeMinute(_vm.formatTime(i))
                  },
                },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.formatTime(i)) + "\n        "
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titles" }, [
      _c("div", [_vm._v("Hour")]),
      _vm._v(" "),
      _c("div", [_vm._v("Minute")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }